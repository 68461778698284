import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import RenderCMS from 'src/components/RenderCMS'
import type {
  InstitutionalPageQueryQuery,
  InstitutionalPageQueryQueryVariables,
} from '@generated/graphql'

const Page: FCC<
  PageProps<InstitutionalPageQueryQuery, InstitutionalPageQueryQueryVariables>
> = (props) => {
  const {
    data: { site, pageData },
    location: { host, pathname },
    params: { slug },
  } = props

  const title =
    pageData?.seo?.siteMetadataWithSlug?.title ??
    site?.siteMetadata?.title ??
    ''

  const docTitle = pageData?.config?.config?.title

  const canonical =
    host !== undefined
      ? `https://${host}/${slug}`
      : slug
      ? `/${slug}`
      : pathname

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: pageData?.seo?.siteMetadataWithSlug?.slug ?? '/',
            name: docTitle ?? 'Institutional',
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <RenderCMS
        sections={pageData?.sections}
        extraProps={{
          Breadcrumb: {
            breadcrumbList: [
              {
                item: pageData?.seo?.siteMetadataWithSlug?.slug ?? '/',
                name: docTitle ?? 'Institutional',
                position: 1,
              },
            ],
            name: docTitle ?? 'Institutional',
          },
          InstitutionalText: {
            title: docTitle ?? '',
            slug: pageData?.seo?.siteMetadataWithSlug?.slug ?? '',
          },
        }}
      />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query InstitutionalPageQuery($id: String!) {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }

    pageData: cmsInstitutionalPage(id: { eq: $id }) {
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
        }
      }
      sections {
        data
        id
        name
      }
      name
      config {
        config {
          title
        }
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)
